<template>
  <default-component without-header />
</template>

<script>
import DefaultComponent from '@/layouts/default.vue';

export default {
  name: 'WithoutHeader',
  components: {
    DefaultComponent,
  },
};
</script>
