import throttle from 'lodash/throttle';
import delay from '~/utils/delay';

export default {
  state() {
    return {
      user: false,
      loginEmail: '', // Запоминаем при регистрации

      // Запоминаем что открыли форму. Для перехода к форме регистрации со страниц оферты и политики
      showRegisterForm: false,
      // Куда отправлять после регистрации
      afterRegister: null,
      notifications: [],
      // форма принятия политики кук
      acceptCookiePrivacy: true,

      materialCategories: [],

      windowSize: 0,
    };
  },
  /* eslint-disable no-param-reassign */
  mutations: {
    login(state, user) {
      state.user = user;
    },
    logout(state) {
      state.user = false;
    },
    loginEmail(state, data) {
      state.loginEmail = data;
    },
    showRegisterForm(state, data) {
      state.showRegisterForm = data;
    },
    afterRegister(state, data) {
      state.afterRegister = data;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications || [];
    },
    acceptCookiePrivacy(state, status) {
      state.acceptCookiePrivacy = status;
    },

    setMaterialCategories(state, categories) {
      state.materialCategories = categories;
    },

    setWindowSize(state, windowSize) {
      state.windowSize = windowSize;
    },
  },
  /* eslint-enable */
  getters: {
    user(state) {
      return state.user;
    },
    loginEmail(state) {
      return state.loginEmail;
    },
    showRegisterForm(state) {
      return state.showRegisterForm;
    },
    afterRegister(state) {
      return state.afterRegister;
    },

    notifications(state) {
      return state.notifications;
    },
    acceptCookiePrivacy(state) {
      return state.acceptCookiePrivacy;
    },

    getMaterialCategories(state) {
      return state.materialCategories || [];
    },
    getFlatMaterialCategories(state) {
      return state.materialCategories.reduce((items, item) => {
        items.push(item, ...(item.children || []));
        return items;
      }, []);
    },

    getWindowSize(state) {
      return state.windowSize;
    },
    isMobile(state) {
      const TABLET_WIDTH = 576;
      return state.windowSize <= TABLET_WIDTH;
    },
    isTablet(state) {
      const TABLET_WIDTH = 768;
      return state.windowSize <= TABLET_WIDTH;
    },
    isLaptop(state) {
      const LAPTOP_WIDTH = 1024;
      return state.windowSize <= LAPTOP_WIDTH;
    },
    isDesktop(state) {
      const DESKTOP_WIDTH = 1440;
      return state.windowSize <= DESKTOP_WIDTH;
    },
  },
  actions: {
    login(state, user) {
      // state.dispatch('autorize', user.token);
      state.commit('login', user);
    },
    autorize(state, token) {
      const cookieDomain = window?.location?.hostname || process.env.cookie_domain;
      this.$cookies.set('auth', token, { domain: cookieDomain, path: '/' });
    },
    async logout(state) {
      const cookieDomain = window?.location?.hostname || process.env.cookie_domain;
      this.$cookies.remove('auth', { domain: cookieDomain });

      document.cookie = `auth=; path=/; domain=${cookieDomain}; max-age=-1`;

      // из-за проблем с cookie_domain, там использовалось основной домен
      // и это мешало выйти из кабинета synergy.ru
      document.cookie = 'auth=; path=/; domain=.synergy.ru; max-age=-1';
      document.cookie = 'auth=; path=/; domain=school.synergy.ru; max-age=-1';
      document.cookie = 'auth=; path=/; domain=.school.synergy.ru; max-age=-1';
      document.cookie = 'auth=; path=/; domain=school.syndev.ru; max-age=-1';
      document.cookie = 'auth=; path=/; domain=.school.syndev.ru; max-age=-1';

      localStorage.removeItem('refreshToken');
      state.commit('logout');

      await delay(100);
      return false;
    },
    storeRef() {
      const cookieDomain = window?.location?.hostname || process.env.cookie_domain;
      this.$cookies.set('lref', 'school', { domain: cookieDomain });
    },

    async fetchMaterialCategories({ commit }) {
      const categories = await this.$api.getPublicMaterialCategories();
      commit(
        'setMaterialCategories',
        [...categories].sort((a, b) => (a.position - b.position)),
      );
      return categories;
    },

    // eslint-disable-next-line
    setWindowSize: throttle(function ({ commit }, window) {
      // commit('setWindowSize', window.target.outerWidth);
      commit('setWindowSize', window.target.innerWidth);
    }, 300),
  },
};
