<template>
  <nuxt />
</template>

<script>
import { mapActions } from 'vuex';
import { addAdmitadScript, addDeduplicationScript } from '~/utils/addAdmitadScript';

export default {
  name: 'EmptyLayout',
  // head() {
  //   return {
  //     link: [
  //       // { rel: 'canonical' },
  //       { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true },
  //       { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  //       {
  //         rel: 'stylesheet',
  //         href: 'https://fonts.googleapis.com/css2?family=Ysabeau:wght@400;500;600;700&display=swap',
  //       },
  //     ],
  //   };
  // },

  mounted() {
    addDeduplicationScript();
    addAdmitadScript();

    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize({ target: window });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWindowSize);
  },

  methods: {
    ...mapActions({
      setWindowSize: 'setWindowSize',
    }),
  },
};
</script>

<style scoped lang="scss">

// артефакты от старых страниц
::v-deep section {
  margin-bottom: 0;

  h2,
  h3 {
    margin: 0;
  }
}

</style>
