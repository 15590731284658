/**
 * Mixins
 */
import { validationMixin } from 'vuelidate';

/**
 * Validations
 */
import { required, email } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],

  data: () => ({
    titleForSend: '',
    name: '',
    phone: '',
    email: '',
    sending: false,
    agree: true,
    agreeNotifications: true,
    submitError: [],

    checkValidate: false,
  }),

  watch: {
    phone() {
      const idx = this.submitError.findIndex((item) => (
        item.objectField === 'phone' && item.message === 'уже существует'
      ));

      if (idx > -1) {
        this.submitError = [
          ...this.submitError.slice(0, idx),
          ...this.submitError.slice(idx + 1),
        ];
      }
    },
    email() {
      const idx = this.submitError.findIndex((item) => (
        item.objectField === 'email' && item.message === 'уже существует'
      ));

      if (idx > -1) {
        this.submitError = [
          ...this.submitError.slice(0, idx),
          ...this.submitError.slice(idx + 1),
        ];
      }
    },
  },

  methods: {
    clearSubmitError(field) {
      this.submitError = this.submitError.filter((e) => e.objectField !== field);
    },
    skipSpace(event) {
      event.preventDefault();
      return false;
    },
    validate() {
      let valid = true;

      this.$v.$touch();
      if (!this.valid) valid = false;

      const nameField = this.$refs.nameRegistrationField || this.$refs.nameField;
      if (nameField) {
        if (!nameField.validate()) valid = false;
      }

      if (this.$refs.emailField) {
        if (!this.$refs.emailField.validate()) valid = false;
      }

      if (this.$refs.phoneField) {
        if (!this.$refs.phoneField.validate()) valid = false;
      }

      if (this.$refs.emailField) {
        if (!this.$refs.emailField.validate()) valid = false;
      }

      return valid;
    },
    submit() {
      if (this.sending) return;
      if (!this.validate()) return;

      this.submitError = [];

      this.$emit('send');
      this.sending = true;

      this.$api
        .register({
          name: this.name,
          email: this.email,
          phone: this.phone.replace('+', ''),
          emailMe: this.agreeNotifications,
          ...(this.registerAdditionParams || {}),
          landCode: this.landCode,
          formTitle: this.titleForSend,
        })
        .then((data) => {
          if (!data.success) {
            this.$emit('error');
            this.submitError = data.errors;
          } else {
            this.$store.dispatch('login', data.student);
            if (this.afterRegister) {
              return this.afterRegister(data.student);
            }
            this.$root.$emit('registerDone', data.student);
            // https://jr.synergy.ru/browse/ESK-3628
            window.location = '/classroom';
          }
          return true;
        })
        .catch(() => {
          this.$emit('error');
        })
        .finally(() => {
          this.$emit('done');
          this.sending = false;
        });
    },
  },

  /**
   * TODO в новом дизайне не актуален
   */
  validations: {
    phone: {
      required,
      unUsed() {
        return !this.usedPhone;
      },
    },
    email: {
      required,
      email,
      unUsed() {
        return !this.usedEmail;
      },
    },
  },

  computed: {
    valid() {
      return !this.$v.$invalid && this.agree;
    },
    usedEmail() {
      return (
        this.submitError.length
        && this.submitError.filter((e) => e.objectField === 'email' && e.message === 'уже существует').length > 0
      );
    },
    usedPhone() {
      return (
        this.submitError.length
        && this.submitError.filter((e) => e.objectField === 'phone' && e.message === 'уже существует').length > 0
      );
    },
  },
};
