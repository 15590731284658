<template>
  <default-component without-footer />
</template>

<script>
import DefaultComponent from './default.vue';

export default {
  name: 'WithoutFooter',
  components: {
    DefaultComponent,
  },
};
</script>
