export default (ctx, inject) => {
  // eslint-disable-next-line no-underscore-dangle
  const _ID = 3313611;

  function reachGoal(goal) {
    // eslint-disable-next-line no-undef
    _tmr.push({
      type: 'reachGoal',
      id: _ID,
      goal,
    });
  }

  ctx.$vkPixel = { reachGoal };
  inject('vkPixel', { reachGoal });
};
