// const hostPC = process.env.dev ? 'https://app.snrg.site/':
// 'https://app.synergy.ru/';
const hostPC = '/classroom';
// const hostCources = process.env.dev ? 'https://courses.snrg.site/' : 'https://courses.synergy.ru/';
// const hostSchool = process.env.dev ? 'https://school.snrg.site/' : 'https://school.synergy.ru/';

export default {
  linkToCore(to = '/') {
    return hostPC + to;
  },
  /**
   * Перейти в ЛК
   */
  toCore() {
    window.location = hostPC;
  },

  linkToSetting() {
    return `${hostPC}/settings/`;
  },

  toEnrollSchool(type = 'family') {
    return `${hostPC}/enrollment/${type}`;
  },
  /**
   * Перейти в ЛК
   */
  toSetting() {
    window.location = this.linkToSetting();
  },

  /**
   * Перейти на курсы
   */
  linkToSchool() {
    return `${hostPC}/school/`;
  },
  toSchool() {
    window.location = this.linkToSchool();
  },

  toPaySchool(params) {
    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => `${esc(k)}=${esc(params[k])}`)
      .join('&');
    // eslint-disable-next-line
    return `${hostPC}/school/payment/` + (query ? `?${query}` : '');
  },

  linkToPayment() {
    return `${hostPC}/school/`;
  },
  toToPayment() {
    window.location = this.linkToPayment();
  },
  linkToEducationFiles() {
    return `${hostPC}/education-files`;
  },
};
