import { gql } from '@apollo/client';

export const USER_STUDENT = gql`
  fragment studentData on Student {
    avatarId
    avatar {
      url
    }
    classNumber
    currentTime
    educationType
    email
    emailMe
    enrolled
    id
    name
    paidSchool
    parentPhone
    patronymic
    phone
    registrationLeadId
    secondName

    paidProducts {
      amount
      classNumber
      duration
      premiumEndedAt
      premiumStartedAt
      status
      title
      educationType
    }
  }
`;

export const USER_TEACHER = gql`
  fragment teacherData on Teacher {
    id
    email
    name
    avatar {
      url
    }
    phone
    avatarId
    teacherCode
    currentTime
    secondName
    patronymic
  }
`;

export const USER_TEACHER_CURATOR = gql`
  fragment teacherCuratorData on TeacherCurator {
    avatarId
    avatar {
      url
    }
    email
    id
    name
    patronymic
    phone
    secondName
  }
`;

export const USER_SCHOOL_ADMIN = gql`
  fragment schoolAdminData on SchoolAdmin {
    id
    email
    name
    avatar {
      url
    }
    phone
    avatarId
    currentTime
    secondName
    patronymic
  }
`;

export const GET_ME = gql`
  query me {
    me {
      student {
        ...studentData
      }
      teacher {
        ...teacherData
      }
      teacherCurator {
        ...teacherCuratorData
      }
      schoolAdmin {
        ...schoolAdminData
      }
    }
  }
  ${USER_STUDENT}
  ${USER_TEACHER}
  ${USER_TEACHER_CURATOR}
  ${USER_SCHOOL_ADMIN}
`;

export const LOGIN = gql`
  mutation signIn(
    $email: String!
    $fingerprint: String!
    $password: String!
  ) {
    signIn(
      email: $email
      fingerprint: $fingerprint
      password: $password
    ) {
      student {
        ...studentData
      }
      teacher {
        ...teacherData
      }
      teacherCurator {
        ...teacherCuratorData
      }
      schoolAdmin {
        ...schoolAdminData
      }
      success
      errors {
        message
      }
    }
  }
  ${USER_STUDENT}
  ${USER_TEACHER}
  ${USER_TEACHER_CURATOR}
  ${USER_SCHOOL_ADMIN}
`;

export const REGISTER = gql`
  mutation signUp(
    $classNumber: Int
    $email: String!
    $emailMe: Boolean
    $fingerprint: String!
    $formTitle: String
    $landCode: String
    $name: String!
    $phone: String!
    $type: String!
    $utmData: JSON
  ) {
    signUp(
      classNumber: $classNumber
      email: $email
      emailMe: $emailMe
      fingerprint: $fingerprint
      formTitle: $formTitle
      landCode: $landCode
      name: $name
      phone: $phone
      type: $type
      utmData: $utmData
    ) {
      success
      errors {
        message
        objectField
        valid
      }
      student {
        ...studentData
      }
    }
  }
  ${USER_STUDENT}
`;

export const REMEMBER = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email, refferer: "school") {
      errors {
        field
        message
      }
      success
    }
  }
`;

export const GET_TUTORS = gql`
  query {
    tutors {
      name
      bio
      photoUrl
      position
      regalia
      snippet
      orderPosition
      kind
      photoUrls {
        large
        medium
        small
      }
      classes {
        grade
      }
      courses {
        title
        slug
        grade
      }
      subjects {
        id
        name
        slug
      }
    }
  }
`;

/** ** Mutations **** */

export const LOGOUT = gql`
  mutation signOut($fingerprint: String!) {
    signOut(fingerprint: $fingerprint) {
      success
    }
  }
`;

export const CREATE_FEED_BACK = gql`
  mutation createFeedBack(
    $name: String
    $email: String
    $phone: String
    $callMe: Boolean
    $message: String
    $classNumber: Int
    $utmData: JSON
    $kind: String
    $department: String
  ) {
    createFeedBack(
      name: $name
      email: $email
      phone: $phone
      callMe: $callMe
      message: $message
      classNumber: $classNumber
      utmData: $utmData
      kind: $kind
      department: $department
    ) {
      success
      errors {
        message
        objectField
        valid
      }
    }
  }
`;

/** ** landing **** */

export const SEND_LEAD = gql`
  mutation sendLead(
    $name: String!,
    $phone: String!,
    $email: String!,
    $landCode: String!,
    $formTitle: String!,
    $utmData: JSON,
    $latestComment: String,
    $visitHistory: [JSON!],
  ) {
    sendLead(
      name: $name,
      phone: $phone,
      email: $email,
      landCode: $landCode,
      formTitle: $formTitle,
      utmData: $utmData,
      latestComment: $latestComment,
      visitHistory: $visitHistory,
    ) {
      success
      errors {
        objectField
        message
        valid
      }
      lead {
        id
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation updateLead(
    $id: ID!,
    $comment: String!,
  ) {
    updateLead(
      id: $id,
      comment: $comment,
    ) {
      success
      errors {
        objectField
        message
        valid
      }
    }
  }
`;

export const PAID_REQUEST = gql`
  mutation paidSchoolRequest(
    $productId: ID!
    $classNumber: Int
    $callbackSuccess: String!
    $educationType: String !
    $promoCode: String
  ){
    paidSchoolRequest(
      productId: $productId
      classNumber: $classNumber
      callbackSuccess: $callbackSuccess
      educationType: $educationType
      promoCode: $promoCode
    ) {
      errors {
        objectField,
        message,
        valid
      },
      success,
      link
    }
  }`;

export const PURCHASE_WITHOUT_SIGN_IN_REQUEST = gql`
  mutation purchaseWithoutSignInRequest(
    $callbackSuccess: String!
    $email: String!
    $phone: String!
    $productId: ID!
  ){
    purchaseWithoutSignInRequest (
      callbackSuccess: $callbackSuccess
      email: $email
      phone: $phone
      productId: $productId
    ) {
      errors {
        objectField
        message
        valid
      }
      success
      link
    }
  }`;

export const GET_PRODUCTS = gql`
  query getProducts($kind: String!){
    getProducts(kind: $kind) {
      description
      id
      name
      price
    }
  }`;

export const PURCHASE_PRODUCT = gql`
  mutation purchaseProduct(
    $callbackSuccess: String!
    $productId: ID!
    $promoCode: String
    $startTrial: Boolean
  ){
    purchaseProduct (
      callbackSuccess: $callbackSuccess
      productId: $productId
      promoCode: $promoCode
      startTrial: $startTrial
    ) {
      errors {
        objectField,
        message,
        valid
      },
      success,
      link
    }
  }`;

export const CONFIRM_EMAIL = gql`
  mutation confirmEmail($confirmationToken: String!){
    confirmEmail(confirmationToken: $confirmationToken) {
      errors {
        field
        message
        valid
      }
      success
    }
  }
`;

export const GET_BANNERS = gql`
  query getMarketingContents(
    $categoryIds: [ID!]
    $contentType: String
    $page: Int
    $perPage: Int
    $published: Boolean
  ) {
    getMarketingContents(
      categoryIds: $categoryIds
      contentType: $contentType
      page: $page
      perPage: $perPage
      published: $published
    ) {
      marketingContents {
        categories {
          id
          ancestry
          children {
            id
          }
        }
        data
        footer
        general
        header
        id
        images {
          url
        }
      }
    }
  }
`;

export const GET_ONBOARDING_QUESTIONS = gql`
  query getOnboardingQuestions ($questionnarieName: String!) {
    getOnboardingQuestions (questionnarieName: $questionnarieName) {
      group
      name
      position
      text
      questionnarieName
    }
  }
`;

export const SAVE_QUESTIONNARIE_ANSWERS = gql`
  mutation saveQuestionnarieAnswers(
    $answers: [QuestionAnswerInput!]!
    $leadId: ID!
    $questionnarieName: String!
  ) {
    saveQuestionnarieAnswers(
      answers: $answers
      leadId: $leadId
      questionnarieName: $questionnarieName
    ) {
      errors {
        objectField
        message
        valid
      }
      success
      lead {
        id
      }
    }
  }
`;
