/* eslint-disable no-param-reassign */

import { MODAL_TYPES } from '~/components/v2/common/AppHeader/data';

export default {
  state() {
    return {
      modalType: null,
    };
  },

  mutations: {
    setModalType(state, type) {
      state.modalType = type;
    },
    closeModal(state) {
      state.modalType = null;
    },
  },

  getters: {
    currentModal(state) {
      return state.modalType;
    },
  },

  actions: {
    openModal({ commit }, modalType) {
      if (Object.keys(MODAL_TYPES).includes(modalType)) {
        commit('setModalType', modalType);
      } else {
        // eslint-disable-next-line no-console
        console.error('Не правильный тип модалки');
      }
    },
    closeModal({ commit }) {
      commit('closeModal');
    },
  },
};
