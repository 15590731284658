<template>
  <main class="v2-layout">
    <common-modals />
    <page-bottom-banner />
    <scroll-top-button />

    <app-header v-if="newHeaderPage" />
    <app-header-old v-else />

    <app-header-modal v-if="currentModal" />
    <app-header-modal-old v-if="showHeaderModal" />

    <nuxt class="page" />
    <app-footer />

    <notifications position="top right" />
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { addAdmitadScript, addDeduplicationScript } from '~/utils/addAdmitadScript';

import PageBottomBanner from '~/components/school/PageBottomBanner.vue';
import AppHeader from '~/components/v2/common/AppHeader/AppHeader.vue';
import AppHeaderModalOld from '~/components/v2/common/AppHeaderModalOld.vue';
import AppFooter from '~/components/v2/common/AppFooter.vue';
import ScrollTopButton from '~/components/v2/common/ScrollTopButton.vue';
import CommonModals from '~/components/v2/modals/CommonModals.vue';
import AppHeaderOld from '../components/v2/common/AppHeaderOld.vue';
import AppHeaderModal from '../components/v2/common/AppHeader/AppHeaderModal.vue';

export default {
  name: 'DefaultLayoutV2',
  components: {
    AppHeaderModal,
    AppHeaderOld,
    CommonModals,
    PageBottomBanner,
    ScrollTopButton,
    AppFooter,
    AppHeader,
    AppHeaderModalOld,
  },
  head() {
    return {
      link: [
        { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true },
        { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Ysabeau:wght@400;500;600;700&display=swap',
        },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('headerModals', {
      showHeaderModal: 'showHeaderModal',
    }),
    ...mapGetters('appHeaderModals', {
      currentModal: 'currentModal',
    }),

    newHeaderPage() {
      const newRouteNames = ['index', 'otzyvy', 'school-university'];
      return newRouteNames.includes(this.$route.name);
    },
  },

  mounted() {
    addDeduplicationScript();
    addAdmitadScript();

    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize({ target: window });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWindowSize);
  },

  methods: {
    ...mapActions({
      setWindowSize: 'setWindowSize',
    }),
  },
};
</script>

<style scoped lang="scss">
.v2-layout {
  overflow: hidden;
  background-color: #ECEDF0;

  ::v-deep .page-banner ~ .header {
    @include media-up(tablet) {
      top: 100px;
    }
  }
  ::v-deep .common-modals ~ .page-banner {
    display: none;
  }

  ::v-deep .common-modals ~ .header {
    top: 0 !important;
  }

  ::v-deep .page-bottom-banner {
    & ~ .stb-button {
      bottom: calc(var(--scale) * 120);
    }

    & ~ .header__wrapper header,
    & ~ .header {
      @include media-down($size-tablet) {
        top: calc(var(--scale) * 50);

        .header__inner {
          top: 0;
        }
      }
    }

    & ~ .page {
      @include media-down($size-tablet) {
        margin-top: calc(var(--scale) * 60);
      }
    }

    & ~ .footer {
      padding-bottom: calc(var(--scale) * 100);

      @include media-down($size-tablet) {
        padding-bottom: calc(var(--scale) * 80);
      }
    }
  }

  &:has(.external-adult-bottom-banner) {
    @include media-down(tablet) {
      .header {
        top: 60px;

        .header__inner {
          top: 0;
        }
      }

      .page {
        margin-top: 60px;
      }
    }
  }

  &:has(.page-bottom-banner) {
    @include media-between(769px, $size-tablet) {
      ::v-deep .header__mobile {
        bottom: 76px;
      }
    }
  }

  &:has(.dropdown-menu__mobile) {
    @include media-down($size-tablet) {
      .page-bottom-banner {
        display: none;
      }

      ::v-deep .header {
        top: 0 !important;
      }
    }
  }
}

main * {
  font-family: 'TildaSans', sans-serif;
}

::v-deep button {
  font-family: 'TildaSans', sans-serif;
}

::v-deep input {
  font-family: 'TildaSans', sans-serif !important;
}

// артефакты от старых страниц
::v-deep section {
  margin-bottom: 0;

  h2,
  h3 {
    margin: 0;
  }
}
</style>
